import bsCustomFileInput from "bs-custom-file-input";
window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

    require('bootstrap');
    require('select2');
    require('select2/dist/js/i18n/ro');
    require('chart.js');
    window.moment = require('moment');
    require('daterangepicker');
    require('jquery-ui/ui/widget');
    require('jquery-ui/ui/data');
    require('jquery-ui/ui/scroll-parent');
    require('jquery-ui/ui/widgets/sortable');


    window.Swal = require('sweetalert2');

    bsCustomFileInput.init();

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    //check notifications
    let $notifications = $('#notifications');
    if($notifications.find('.notification-toggle').hasClass('beep')){
        let html = $('<div />').addClass('dropdown-list').css('width','auto').append($notifications.find('.dropdown-list-content')[0].outerHTML);
        let readAllText = $('#notificationsReadAll a').text();
        Swal.fire({
            title: "Aveti notificari necitite",
            icon: 'info',
            html: html,
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText:
                '<i class="fa fa-check-circle"></i> ' + readAllText,
            confirmButtonAriaLabel: readAllText,
            cancelButtonText: 'Ascunde',
            cancelButtonAriaLabel: 'Ascunde'
        }).then( (result) => {
            if(result.value){
                window.location.href = $('#notificationsReadAll a').attr('href');
            }
        });
    }
    /*
    setTimeout(function(){

    },30000);*/

    $(function() {
        //select 2
        /*
        $('.select2').select2({
            width: '100%'
        });
        */
        //swal error
        let swalCont = '[data-swal]';
        if($(swalCont).length > 0){
            let swalIcon = $(swalCont).data('swal');
            Swal.fire({
                icon: swalIcon,
                html: $(swalCont).find('.text').html(),
                timer: (swalIcon === 'success' ? 1500 : 5000),
                showConfirmButton: (swalIcon !== 'success')
            });
        }
        //table warning background
        $('.table-warnings').find('.badge-danger').closest('tr').addClass('tr-warning-alt');
        //table row click
        $('.td-link').click(function(){
            let href = $(this).parent().find('a').attr('href');
            if(typeof href !== "undefined") {
                window.location.href = href;
            }
        });
        $('.table-link tr td:not(:last-child)').click(function(e){
            if(e.ctrlKey){
                $(this).parent().toggleClass('hover');
            }else{
                let href = $(this).parent().find('a').attr('href');
                if(typeof href !== 'undefined') {
                    window.location.href = href;
                }
            }
        });
        //hash scroll
        let hash = window.location.hash.substr(1);
        if(hash !== null && hash.length > 0 && $('#' + hash).length > 0) {
            $('html, body').animate({
                scrollTop: $('#' + hash).offset().top
            }, 'slow', function(){
                history.replaceState(null, null, ' ');
            });
        }
        //confirm modal
        $('.btn-confirm').on('click',function(e){
            let form = $(this).parents('form');
            let tempElement = $("<input type='hidden'/>");
            tempElement.attr("name", this.name)
                .val($(this).val())
                .appendTo(form);
            Swal.fire({
                title: $(this).data('title'),
                text: $(this).data('text'),
                showCancelButton: true,
                icon: 'info',
                confirmButtonColor: "#47c363",
                confirmButtonText: "Da",
                cancelButtonText: "Nu"
            }).then( (result) => {
                if (result.value) {
                    form.submit();
                }
                tempElement.remove();
            });
            e.preventDefault();
        });
        //confirm modal href
        $('.btn-confirm-href').on('click',function(e){
            e.preventDefault();
            let href = $(this).attr('href');
            Swal.fire({
                title: $(this).data('title'),
                text: $(this).data('text'),
                showCancelButton: true,
                icon: 'info',
                confirmButtonColor: "#47c363",
                confirmButtonText: "Da",
                cancelButtonText: "Nu"
            }).then( (result) => {
                if (result.value) {
                    window.location.href = href;
                }
            });
            return false;
        });
        //file modal
        $(".btn-file-modal").click(function() {
            let button = $(this);
            let load = button.data('load');
            let mime = button.data('mime');
            let body = '';
            if(mime.match('image\/*'))
                body = '<img src="' + load + '" class="img-fluid" />';
            else if(mime === "application/pdf")
                body = '<object data="' + load + '" class="embed-responsive-item" width="100%" height="700" type="' + mime + '">';
            else
                body = '<iframe class="embed-responsive-item" height="700" width="100%" src="'+ load + '" />';
            Swal.fire({
                title: button.attr('title'),
                html: body,
                grow: 'fullscreen',
                confirmButtonText: 'Inchide'
            });
        });
        //toggle
        let selParEl = '#selectParticipare';
        if($(selParEl).length) {
            let handleSelPar = function () {
                if ($(selParEl).val() === "1") {
                    $('.toggleSelPar').show();
                } else {
                    $('.toggleSelPar').hide();
                }
            };
            handleSelPar();
            $(selParEl).change(handleSelPar);
        }
        //range
        if($('.range-picker').length){
            $(document).on('input change', '.range-picker', function () {
                $(this).closest('.form-group').find('.range-label').text($(this).val());
                return true;
            })
        }
        //modal for files
        $('#fileModal').on('show.bs.modal', function (e) {
            modal.find('.modal-title').text(title);
            modal.find('.modal-body').html(body);
        });
        //stage 6
        if($('#inputPaymentType').length) {
            $('#inputPaymentType').change(function () {
                let carSold = $('#inputPriceSold');
                let payAmm = $('#inputPaymentAmount');
                if ($(this).val() === "2" && !payAmm.val() && carSold.val()) {
                    payAmm.val(carSold.val());
                    $('select[name="currency"]').val($('select[name="currency_sold"]').val()).change();
                }
            });
        }
        //comments
        if($('#commentModalForm').length) {
            //add car model
            $('.addCommentModal').fireModal({
                title: 'Adaugare comentariu',
                body: $("#commentModalForm"),
                footerClass: 'bg-whitesmoke',
                autoFocus: false,
                created: function(modal, opt){
                    let button = $(this);
                    let reply = 0;
                    let replying = modal.find('#replying');
                    if (button.data('reply')) {
                        reply = button.data('reply');
                        replying.removeClass('d-none').text(button.attr('title'));
                        modal.find('input[name="reply_to"]').val(reply);
                    } else {
                        replying.addClass('d-none').text('');
                    }
                },
                onFormSubmit: function (modal, e, form) {
                    e.preventDefault();
                    let modalForm = modal.find('form');
                    $.ajax({
                        url: modalForm.attr('action'),
                        method: 'POST',
                        data: modalForm.serialize(),
                        success: function (data) {
                            if(!data.type || data.type === "error"){
                                Swal.fire("Eroare", "A aparut o eroare la adaugarea comentariului!", 'error');
                            }else {
                                location.reload();
                            }
                        },
                        error: function (data) {
                            handleAjaxError(data);
                        },
                        complete: function (data) {
                            form.stopProgress();
                        },

                    })
                },
                buttons: [
                    {
                        text: 'Salveaza',
                        submit: true,
                        class: 'btn btn-primary btn-shadow',
                        handler: function(modal) {
                        }
                    }
                ]
            });
        }
        //modal ajax handle error
        function handleAjaxError(data){
            let text = "";
            if(typeof data.responseJSON !== 'undefined') {
                $.each( data.responseJSON.errors, function( key, value) {
                    text += value + "<br />";
                });
            } else {
                text = "A aparut o eroare la inserare";
            }
            Swal.fire("Eroare", text, 'error');
        }
        //modal success
        function handleModalSuccess(data,target, message_title, modal, e){
            if(data.type === 'success') {
                Swal.fire({
                    title: message_title,
                    text: data.message,
                    icon: "success",
                    timer: 2000,
                    showConfirmButton: false
                });
                //append new provider
                var newProvider = new Option(data.provider.name, data.provider.id, false, false);
                $(target).append(newProvider).trigger('change');
                $(target).val(data.provider.id).trigger('change');
                //hide modal
                modal.modal('hide');
                $(e.target).trigger('reset');
            } else if(data.type === 'error'){
                Swal.fire("Eroare",data.message,"error");
            } else {
                Swal.fire("Eroare","Eroare necunoscuta la adaugare, incercati sa adaugati datele pe pagina dedicata","error");
            }
        }
        //add car model
        $('#addModelModal').fireModal({
            title: 'Adaugare model',
            body: $("#modal-model"),
            footerClass: 'bg-whitesmoke',
            autoFocus: false,
            onFormSubmit: function (modal, e, form) {
                e.preventDefault();
                $.ajax({
                    url: $(e.target).attr('action'),
                    method: 'POST',
                    data: $(e.target).serialize(),
                    success: function (data) {
                        handleModalSuccess(data,'#inputModel', 'Model adaugat', modal, e);
                    },
                    error: function (data) {
                        handleAjaxError(data);
                    },
                    complete: function (data) {
                        form.stopProgress();
                    },

                })
            },
            buttons: [
                {
                    text: 'Salveaza',
                    submit: true,
                    class: 'btn btn-primary btn-shadow',
                    handler: function(modal) {
                    }
                }
            ]
        });
        //add provider
        $("#addProviderModal").fireModal({
            title: '',
            body: $("#modal-provider"),
            footerClass: 'bg-whitesmoke',
            autoFocus: false,
            onFormSubmit: function(modal, e, form) {
                e.preventDefault();
                $.ajax({
                    url: $(e.target).attr('action'),
                    method: 'POST',
                    data:  $(e.target).serialize(),
                    success: function (data) {
                        handleModalSuccess(data,'#inputIdProvider', 'Furnizor adaugat', modal, e);
                    },
                    error: function(data) {
                        handleAjaxError(data);
                    },
                    complete: function(data) {
                        form.stopProgress();
                    },

                })
            },
            buttons: [
                {
                    text: 'Salveaza',
                    submit: true,
                    class: 'btn btn-primary btn-shadow',
                    handler: function(modal) {
                    }
                }
            ]
        });
    });
} catch (e) {
    console.log(e);
}
